@use "../components/Application/application.scss";
@use "../components/Artwork/Details.styles.scss";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Open+Sans:wght@300;400;500;600;700&display=swap");

h1,
h2,
h3,
h4,
h5 {
	font-family: "Cormorant Garamond", serif;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgb(244 244 244);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

//paginator
.mwaza_active a {
	background-color: black;
	color: white;
}
.mwaza_container {
	display: flex;
	list-style: none;
	gap: 10px;
	justify-content: center;
	justify-items: center;
	margin: 60px 0 200px;
	padding: 0;
}
.mwaza_break a {
	border: 1px solid rgba(0, 0, 0, 0.2);
	height: 30px;
	min-width: 30px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	cursor: pointer;
}

.previous,
.next,
.break {
	display: flex;
	align-items: center;
	justify-content: center;
}

.previous,
.next,
.break a {
	margin: 0;
	line-height: 1;
	padding: 0;
}
