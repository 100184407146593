$edges: 40px;
.application__main {
	max-width: 1280px;
	margin: 0 auto;
	width: calc(100% - 30px);
}

.layout {
	&__hero {
		background-color: #fff;
		margin-bottom: 10px;
		padding: $edges;
		@media only screen and (max-width: 600px) {
			padding: 30px 20px;
		}

		&_title {
			font-size: 70px;
			font-weight: 200;
			margin: 0;
			letter-spacing: -1px;
			color: #000;
			@media only screen and (max-width: 600px) {
				font-size: 40px;
			}
		}
		&_description {
			max-width: 700px;
			font-family: "Open Sans", sans-serif;
			font-size: 15px;
			line-height: 1.6;
			color: #666;
		}
	}
	&__artworks {
		display: grid;
		grid-template-columns: auto auto auto auto;
		gap: 30px;
		padding: 10px 0;

		@media only screen and (max-width: 600px) {
			grid-template-columns: auto;
		}
	}

	&__artwork {
		border: rgb(238 238 238) 1px solid;
		padding-bottom: 30px;
	}

	&__mockButton {
		height: 50px;
		width: 200px;
		background-color: #000;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		border-radius: 3px;
	}
}

.artwork {
	border: rgb(238 238 238) 1px solid;
	padding-bottom: 30px;

	&__title {
		font-weight: 400;
		font-size: 24px;
		color: #000;
		letter-spacing: -0.6px;
		margin: 0;
		padding: 20px 20px 0;
	}
	&__artist {
		padding: 0 20px;
		font-size: 13px;
		color: rgba(#000, 0.5);
	}
	a {
		text-decoration: none;
		//
	}
}

.header {
	&__container {
		position: sticky;
		top: 0;
		z-index: 99;
		height: 80px;
		display: flex;
		align-items: center;
		background-color: white;
		margin-bottom: 10px;
		padding: 0 $edges;
		border-bottom: rgb(238, 238, 238) 1px solid;
		gap: 30px;
		@media only screen and (max-width: 600px) {
			padding: 0 20px;
		}
	}
}

.ui {
	&__image {
		width: 100%;
		//height: 100%;
		object-fit: cover;
	}
}

//// loader styles
/* Source: loader CSS style by Nobuaki Honma on CodePen: https://codepen.io/nobuakihonma/pen/dYbqLQ */

.loader-5 {
	height: 32px;
	width: 32px;
	-webkit-animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-1 {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes loader-5-1 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader-5::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: auto;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-2 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(24px, 0, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-2 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(24px, 0, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: auto;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-3 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(-24px, 0, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-3 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(-24px, 0, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5 span {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader-5 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: auto;
	right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-4 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(0, 24px, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-4 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(0, 24px, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
.loader-5 span::after {
	content: "";
	display: block;
	position: absolute;
	top: auto;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
	animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-5-5 {
	0% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		-webkit-transform: translate3d(0, -24px, 0) scale(0.5);
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0) scale(1);
	}
}
@keyframes loader-5-5 {
	0% {
		transform: translate3d(0, 0, 0) scale(1);
	}
	50% {
		transform: translate3d(0, -24px, 0) scale(0.5);
	}
	100% {
		transform: translate3d(0, 0, 0) scale(1);
	}
}
