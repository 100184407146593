.details {
	display: flex;
	gap: 10px;
	&__loader {
		height: 100vh;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__image {
		flex: 1;
		position: relative;
		min-height: 500px;
		height: calc(90vh - 80px);
		overflow: hidden;
		border-radius: 20px;
		@media only screen and (max-width: 600px) {
			height: 400px;
			min-height: auto;
			flex: none;
		}
	}
	&__title {
		font-size: 40px;
		font-weight: 200;
		margin: 0;
		letter-spacing: -1px;
		color: #000;
		&.white {
			color: #fff;
		}
		@media only screen and (max-width: 600px) {
			font-size: 30px;
		}
	}
	&__section {
		background-color: #fff;
		padding: 20px;
		border-radius: 20px;
		margin-bottom: 10px;

		&.black {
			background-color: #000;
		}
	}
	&__detail {
		font-weight: 500;
		margin-bottom: 10px;
		font-size: 15px;
		&.white {
			color: #fff;
		}
		& > span {
			color: rgba(255, 255, 255, 0.6);
			font-weight: 400;
		}
		& > small {
			font-size: 13px;
			opacity: 0.6;
		}
	}
	&__likes {
		position: absolute;
		right: 20px;
		top: 20px;
		background-color: hsla(0, 0%, 86%, 0.6);
		padding: 10px 20px 8px;
		border-radius: 30px;
		display: flex;
		align-items: center;
		gap: 5px;
		font-weight: 600;
		color: white;
		backdrop-filter: blur(16px);
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

		@media only screen and (max-width: 600px) {
			bottom: 20px;
			top: auto;
		}
	}
	&__tags {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
	}
	&__tag {
		padding: 4px 10px;
		background: rgba(18, 18, 18, 0.04);
		white-space: nowrap;
		font-size: 12px;
		color: #444;
		border-radius: 20px;
		font-weight: 500;
	}
	&__button {
		font-size: 17px;
		font-weight: bold;
		height: 60px;
		width: 100%;
		max-width: 400px;
		background-color: #000;
		border-radius: 10px;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-top: 40px;
		background-color: rgba(255, 255, 255, 1);
	}
	&__heading {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	&__content {
		flex: 1.5;
		margin-bottom: 100px;
	}

	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
}
